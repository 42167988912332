body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 0;
}

a.sui-result__title-link {
  color: #006ab4;
}

p {
  margin-top: 0;
}

p:last-child {
  margin-bottom: 0;
}

input.sui-search-box__submit:hover,
input.sui-search-box__submit {
  background: #006ab4;
}

.header-elements > form {
  flex-grow: 1;
  margin-left: 2rem;
}

.sui-layout-header__inner {
  margin: 1.5rem 0;
}
@media screen and (min-width: 1100px) {
  .sui-layout-header__inner {
    display: flex;
  }
}

.pic {
  font-family: Arial;
  font-size: 14px;
  color: rgb(0,0,145);
  margin-top: .5rem;
}
.logo-boxes {
  display: flex;
  flex: 0 0 410px;
}

.logo-box:first-child {
  border-right: 1px solid #eee;
}
.logo-box {
  max-width: 205px;
  padding: 0.5rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0 0 50%;
}

.logo-box img {
  display: block;
  max-width: 100%;
}

.introduction b {
  color: #006ab4;
}

.sui-search-box__text-input {
  box-shadow: rgb(59 69 79 / 10%) 0px 3px 6px;
}


.introduction {
  /* font-size: 13px; */
  /* color: #444; */
  padding: 2rem 2rem 1rem;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media screen and (min-width: 1100px) {
  .introduction {
    padding: 0 1rem 0 0;
  }
}

@media screen and (min-width: 1500px) {
  .introduction {
    padding: 0.5rem 2rem .5rem 1rem;
  }
}

div.sui-layout-header {
  padding: 0!important;
}

.result {
  display: block;
  list-style: none;
  padding: 2rem 3rem;
  /* margin-bottom: 1.5rem; */
  overflow-wrap: break-word;
  overflow: hidden;
  box-shadow: 0 0 1px 0 rgb(0 0 0 / 10%);
  /* border-radius: 4px; */
  background: #fff;
  border: 1px solid #f0f0f0;
  border-bottom: none;
}
.result.result-unpublished {
  opacity: .66;
}
.result.result-unpublished .result-title {
  opacity: .5;
}
.result > * {
  margin-bottom: 1rem;
}
.result > *:last-child {
  margin-bottom: 0;
}

.result-title {
  text-decoration: none;
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: .5rem;
}

.result em {
  position: relative;
  font-weight: 700;
  font-style: inherit;
}

.result em:after {
  content: "";
  position: absolute;
  top: -3px;
  left: -3px;
  width: -webkit-calc(100% + 6px);
  width: -moz-calc(100% + 6px);
  width: calc(100% + 6px);
  height: -webkit-calc(100% + 6px);
  height: -moz-calc(100% + 6px);
  height: calc(100% + 6px);
  background: rgba(255,200,0,0.12);
  pointer-events: none;
}

.result-published {
  color: #999;
  font-size: .75rem;
}

.result-external-link {
  display: flex;
  align-items: center;
  color: #006ab4;
  /* text-decoration: none; */
  font-size: .9rem;
  margin-bottom: 1rem;
}
.result-external-link img {
  margin-right: .5rem;
  display: inline-block;
}

div.sui-result__body {
  background: #f9f9f9;
  border: 1px solid #eee;
  border-radius: 6px;
  margin-top: 1rem;
  color: #444;
}

.results-count b {
  font-size: 1.2rem;
  margin-right: 3px;
}
.results-filter b {
  padding: 2px 0;
  margin: 2px 5px 2px 0;
  display: inline-block;
}
body .sui-layout-main-header__inner {
  display: block;
}
.sui-layout-main-header__inner em {
  font-style: inherit;
  color: #666;
  /* background: rgba(0,0,0,0.05); */
  background: #eaedf0;
  border-radius: 3px;
  display: inline-block;
  padding: 2px 4px;
  margin: 2px 0;
}
.sui-layout-main-header__inner em span {
  padding-left: 4px;
  color: #bbb;
}

.sui-layout-main-header__inner em span:hover {
  cursor: pointer;
}

.result-details {
  color: #4f4f4f;
  font-size: 13px;
  line-height: 1.4;
}

@media screen and (min-width: 1680px) {
  .result-details {
    display: flex;
  }
}

.result-details ul.result-list {
  list-style: disc;
  padding-left: 13px;
}
.result-details h4 {
  margin: 0 0 .25rem 0;
}

.result-details-side {
  /* width: 150px; */
  margin-right: 30px;
  flex: 0 0 150px;
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
}
@media screen and (min-width: 1680px) {
  .result-details-side {
    border-bottom: none;
    margin-bottom: 0;
  }
}

.result-details-main > div,
.result-details-side > div {
  margin-bottom: 1rem;
}

span.required {
  margin-left: 6px;
  display: inline-block;
  width: 10px;
  height: 10px;
  background: #d91e1e;
  border-radius: 12px;
}

.map-placeholder,
.map {
  position: fixed;
  display: none;
  top: 0;
  right: 0;
  bottom: 0;
}

.map {
  z-index: 9;
}

.sui-layout-body:after {
  background: transparent!important;
}

@media screen and (min-width: 800px) {
  .sui-layout .sui-layout-sidebar {
    width: 32%;
  }
}

.sui-layout-body .sui-layout-body__inner {
  max-width: 100%;
}

@media screen and (min-width: 800px) {
  .app > .spinner-wrapper,
  .app > .loader-wrapper,
  .app .sui-layout {
    padding-right: 40%;
  }

  .sui-layout .sui-layout-main {
    width: 68%;
  }

  .map-placeholder,
  .map {
    width: 40%;
    display: block;
  }
}

.map-placeholder .loader-wrapper {
  background: transparent;
  display: flex;
  flex-direction: row;
  color: #fff;
}
.map-placeholder .loader-wrapper .loader-label {
  margin-left: 1em;
}

.map-placeholder .loader div,
.map-placeholder .loader {
  height: 3em;
  width: 3em;
}

.map-placeholder .loader div {
  background-color: #fff;
}

.map-placeholder {
  background: #ddd;
  z-index: 10;
}


.map .map-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  min-height: 100vh;
}

.map .map-info {
  position: absolute;
  background: #000;
  color: #fff;
  top: 0;
  right: 0;
  z-index: 10;
  font-family: monospace;
  padding: 5px 10px;
  font-size: 11px;
}

/**
 * Loader.
 */

.sui-layout-main-body {
  position: relative;
}
.loader-wrapper.loader-content {
}
.loader-wrapper.loader-app {
  position: absolute;
  top: 120px;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 100vh;
  width: 60%;
}
.loader-wrapper {
  text-align: center;
  color: #006ab4;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: #fcfcfc;
}
.loader {
  position: relative;
  /* height: 3em; */
  margin: 1rem 0;
  width: 100%;
  display: flex;
  height: 10em;
  justify-content: center;
  align-items: center;
}

.loader div {
  display: inline-block;
  height: 10em;
  width: 10em;
  position: absolute;
  background: #bbb;
  background: #006ab4;
  border-radius: 50%;
  /*animation: ripple-loader 1s ease-out infinite; */
  animation: pulse 1.6s ease-in-out infinite;
}

.loader div:nth-child(2) {
  animation-delay: -.5s;
}

@keyframes pulse {
  0% { transform: scale(0); opacity: 0.5;}
  100% { transform: scale(1); opacity: 0;}
}


.spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.map .spinner-wrapper {
  z-index: 10;
  position: relative;
}
.map .spinner-container,
.map-placeholder .spinner-container {
  background: rgba(0,0,0,0.8);
  color: #fff;
}
.spinner-container {
  color: #666;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background: #eee;
  padding: 10px 20px 10px 12px;
  border-radius: 100px;
}
.map-placeholder .spinner-border,
.map .spinner-border {
  border-color: #fff;
  border-right-color: transparent;
}
.spinner-border {
  margin-right: .75rem;
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
  vertical-align: -.125em;
  border: .15em solid #666;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: .75s linear infinite spinner-border;
  animation: .75s linear infinite spinner-border;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.map .map-toolbar {
  z-index: 10;
  position: relative;
  background: #fff;
  display: inline-block;
  padding: .75rem;
  border-radius: 4px;
  font-size: 14px;
  color: #666;
  margin: 10px 0 0 15px;
  box-shadow: 0 0 0 2px rgb(0 0 0 / 10%);

}
.map .map-toolbar label {
  display: flex;
  align-items: center;
}
.map .map-toolbar input {
  margin-right: .5rem;
}

.mapboxgl-popup{
  top: -5px;
}

.warning-message {
  border: 1px solid #ffd600;
  border-radius: 4px;
  background: rgba(255, 214, 0, .15);
  padding: 1rem;
  margin-top: 1rem;
}


body .__react_component_tooltip {
  font-size: 11px;
  padding: 6px 9px;
  max-width: 280px;
  border-radius: 0;
  line-height: 1.4;
}

span[data-tip] {
 border-bottom: 2px dotted #ddd;
}

span[data-tip]:hover {
    cursor: help;
}

.summary-heading {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.reset-search {
  /* margin: 1rem 0 0.5rem; */

}

/*
.btn:hover {
  cursor: pointer;
  background: rgba(0,0,0,0.02);
}

.btn {
  padding: 6px;
  margin: 0;
  border: 1px solid #cccccc;
  border-radius: 4px;
}
*/
 .results-summary .button span {
  margin-right: .5rem;
}
.results-summary .button {
  margin-top: .5rem;
  display: inline-block;
  background: transparent;
}

.results-summary {
  border: 1px solid #eee;
  border-radius: 4px;
  padding: 12px;
  margin-top: 1rem;
}
.results-summary legend {
  margin-bottom: .25rem;
}


.button:hover {
  cursor: pointer;
  border: 1px solid #006ab4;
  color: #006ab4;
  background: #f5fbff;
}
.button {
  padding: 10px 18px;
  border-radius: 4px;
  border: none;
  background: #fff;
  text-transform: uppercase;
  font-size: 11px;
  color: #8b9bad;
  border: 1px solid #8b9bad;
}

button.sui-layout-sidebar-toggle:after {
  content: "Afficher les filtres";
  visibility: visible;
  display: block;
  /* position: absolute; */
  border: 1px solid #006ab4;
  border-radius: 4px;
  margin-top: 20px;
  padding: 10px;
}

.sui-layout-sidebar button.sui-layout-sidebar-toggle:after {
  content: "Sauvegarder les filtres";
}

button.sui-layout-sidebar-toggle {
  visibility: hidden;
}

@media (max-width: 800px) {
  button.sui-layout-sidebar-toggle {
    color: #006ab4;
    padding: 0;
    margin-top: 0;
  }
}

.sui-single-option-facet__link:hover,
.sui-single-option-facet__link:focus,
.sui-facet-view-more,
.sui-search-box__autocomplete-container li em,
.sui-result__title-link,
.sui-result em,
.sui-paging .rc-pagination-jump-prev:hover:after,
.sui-paging .rc-pagination-jump-prev:hover a,
.sui-paging .rc-pagination-jump-next:hover:after,
.sui-paging .rc-pagination-jump-next:hover a,
.sui-paging .rc-pagination-next:hover a,
.sui-paging .rc-pagination-item:hover a,
.sui-paging .rc-pagination-item a {
  color: #006ab4;
}

.sui-facet-search__text-input:focus {
  border-color: #006ab4;
}
